import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CustomTabPanel, a11yProps, createNewTheme } from '../../functions/utils';
import { Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tab, Tabs, Typography } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { KeyboardArrowRight } from '@mui/icons-material';

function Configuracoes({ onChange, colorPrimary, colorSecondary, colorTertiary }, props) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [abaConfiguracao, setAbaConfiguracao] = useState(0);
  const [abaConta, setAbaConta] = useState(0);


  const handleChange = (event, newValue) => {
    setAbaConfiguracao(newValue);
  };

  const handleContaChange = (event, newValue) => {
    setAbaConta(newValue);
  };

  useEffect(() => {

  }, []);

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>

      <div style={{ top: 90, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute" }}>
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Configurações</Typography>
              </Grid>

              <Grid item xs={12} style={{ borderBottom: "1px solid #CCC", marginLeft: 16, marginTop: 20, padding: 0 }}>
                <Tabs value={abaConfiguracao} onChange={handleChange} style={{ maxWidth: 570 }} variant="fullWidth" >
                  <Tab label="Conta" {...a11yProps(0)} style={{ textTransform: "capitalize" }} />
                  <Tab label="Notificações" {...a11yProps(1)} style={{ textTransform: "capitalize" }} />
                  <Tab label="Horários" {...a11yProps(2)} style={{ textTransform: "capitalize" }} />
                  <Tab label="Pagamentos" {...a11yProps(3)} style={{ textTransform: "capitalize" }} />
                </Tabs>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <CustomTabPanel value={abaConfiguracao} index={0}>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={4}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <List>
                            <ListItem disablePadding style={{ backgroundColor: abaConta === 0 ? "#F2F2F2" : "" }} secondaryAction={
                              <IconButton edge="end">
                                <KeyboardArrowRight />
                              </IconButton>
                            } onClick={(event) => handleContaChange(event, 0)}>
                              <ListItemButton>
                                <ListItemText primary="Informações de perfil" primaryTypographyProps={{ fontWeight: "bold" }} />
                              </ListItemButton>
                            </ListItem>
                            <Divider />
                            <ListItem disablePadding style={{ backgroundColor: abaConta === 1 ? "#F2F2F2" : "" }} secondaryAction={
                              <IconButton edge="end">
                                <KeyboardArrowRight />
                              </IconButton>
                            } onClick={(event) => handleContaChange(event, 1)}>
                              <ListItemButton>
                                <ListItemText primary="Email" primaryTypographyProps={{ fontWeight: "bold" }} />
                              </ListItemButton>
                            </ListItem>
                            <Divider />
                            <ListItem disablePadding style={{ backgroundColor: abaConta === 2 ? "#F2F2F2" : "" }} secondaryAction={
                              <IconButton edge="end">
                                <KeyboardArrowRight />
                              </IconButton>
                            } onClick={(event) => handleContaChange(event, 2)}>
                              <ListItemButton>
                                <ListItemText primary="Senha" primaryTypographyProps={{ fontWeight: "bold" }} />
                              </ListItemButton>
                            </ListItem>
                            <Divider />
                            <ListItem disablePadding style={{ backgroundColor: abaConta === 3 ? "#F2F2F2" : "" }} secondaryAction={
                              <IconButton edge="end">
                                <KeyboardArrowRight />
                              </IconButton>
                            } onClick={(event) => handleContaChange(event, 3)}>
                              <ListItemButton>
                                <ListItemText primary="Endereço" primaryTypographyProps={{ fontWeight: "bold" }} />
                              </ListItemButton>
                            </ListItem>
                            <Divider />
                          </List>

                          <Typography style={{ fontWeight: "bold", color: "#1B1B1B", padding: "10px 0px 0px 0px" }}>Outros</Typography>

                          <List>
                            <ListItem disablePadding secondaryAction={
                              <IconButton edge="end">
                                <KeyboardArrowRight />
                              </IconButton>
                            }>
                              <ListItemButton>
                                <ListItemText primary="Excluir conta" primaryTypographyProps={{ fontWeight: "bold" }} />
                              </ListItemButton>
                            </ListItem>
                            <Divider />
                            <ListItem disablePadding secondaryAction={
                              <IconButton edge="end">
                                <KeyboardArrowRight />
                              </IconButton>
                            }>
                              <ListItemButton>
                                <ListItemText primary="Sair" primaryTypographyProps={{ fontWeight: "bold" }} />
                              </ListItemButton>
                            </ListItem>
                            <Divider />
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} style={{ background: "RED" }}>
                    tet
                  </Grid>
                  </Grid>

                  
                </CustomTabPanel>
              </Grid>

              <CustomTabPanel value={abaConfiguracao} index={1}>
                <Typography style={{ color: "#1B1B1B", fontSize: "16px", fontWeight: "bold", marginLeft: "-22px", marginRight: "-22px" }}>
                  Notificações
                </Typography>
              </CustomTabPanel>
              <CustomTabPanel value={abaConfiguracao} index={2}>
                <Typography style={{ color: "#1B1B1B", fontSize: "16px", fontWeight: "bold", marginLeft: "-22px", marginRight: "-22px" }}>
                  Horários
                </Typography>
              </CustomTabPanel>
              <CustomTabPanel value={abaConfiguracao} index={3}>
                <Typography style={{ color: "#1B1B1B", fontSize: "16px", fontWeight: "bold", marginLeft: "-22px", marginRight: "-22px" }}>
                  Pagamentos
                </Typography>
              </CustomTabPanel>
            </Grid>
          </Grid>

        </Grid>
      </div>

    </ThemeProvider>
  );
}

export default Configuracoes;