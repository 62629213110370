import { Alert, AppBar, Avatar, Box, Button, Card, CardContent, CardHeader, Container, Divider, Drawer, FormControl, Grid, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemText, MenuItem, Modal, Select, TextField, Toolbar, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../../functions/utils';

import { BarChart } from '@mui/x-charts/BarChart';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Cookies from 'js-cookie';
import axios from 'axios';
import { HomeOutlined, ArticleOutlined, RestaurantOutlined, ListAltOutlined, QrCodeOutlined, EditOutlined, LayersOutlined, SettingsOutlined, ContactSupportOutlined, Search, ShareOutlined, Close, ContentCopy } from '@mui/icons-material';
import SideMenu from '../../components/SideMenu';

function Home({ onChange, colorPrimary, colorSecondary, colorTertiary }, props) {

  const state = useLocation().state;
  const [statusLoja, setStatusLoja] = useState("aberto");
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {

  }, []);

  const handleConfigure = () => {

  }

  const handleStatus = (event) => {
    setStatusLoja(event.target.value)
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>

      <div style={{ top: 90, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute" }}>
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12} sm={12} md={8}>
            <div style={{ height: "30px" }}></div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Atalhos</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                  <CardContent>
                    <Typography style={{ fontWeight: "bold" }}>
                      Pedidos
                    </Typography>
                    <Typography style={{  }}>
                      Lorem ipsun
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                  <CardContent>
                    <Typography style={{ fontWeight: "bold" }}>
                      Cardápio
                    </Typography>
                    <Typography style={{  }}>
                      Lorem ipsun
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                  <CardContent>
                    <Typography style={{ fontWeight: "bold" }}>
                      Inventário
                    </Typography>
                    <Typography style={{  }}>
                      Lorem ipsun
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                  <CardContent>
                    <Typography style={{ fontWeight: "bold" }}>
                      QR Code
                    </Typography>
                    <Typography style={{  }}>
                      Lorem ipsun
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                  <CardContent>
                    <Typography style={{ fontWeight: "bold" }}>
                      Personalizar
                    </Typography>
                    <Typography style={{  }}>
                      Lorem ipsun
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                  <CardContent>
                    <Typography style={{ fontWeight: "bold" }}>
                      PDV <strong style={{ background: "#F94A3D", color: "white", padding: "0px 10px 0px 10px", borderRadius: 4, width: 70, float: "right" }}>NOVO</strong>
                    </Typography>
                    <Typography style={{  }}>
                      Lorem ipsun
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div style={{ height: "30px" }}></div>
            <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Vendas</Typography>

            <div style={{ height: 16 }}></div>
            <Card variant="outlined" sx={{ borderRadius: "8px" }}>
              <CardHeader
                title={
                  <Typography style={{ display: "flex", alignItems: "center" }}>
                    <strong style={{ fontSize: 16, color: "#999999" }}>R$</strong>
                    <strong style={{ fontSize: 22, color: "#464545" }}> 100,00</strong>


                    <strong style={{ fontSize: 16, color: "#999999", marginLeft: "auto" }}> Ganhos (Semanal)</strong>
                    <strong style={{ fontSize: 22, color: "#464545" }}>
                      <IconButton size="large" color="inherit" onClick={handleConfigure}>
                        <SettingsOutlined />
                      </IconButton>
                    </strong>
                  </Typography>
                }
                style={{ color: "#33540E" }}
              />

              <Divider />
              <CardContent>
                <BarChart
                  xAxis={[{ scaleType: 'band', data: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'] }]}
                  series={[{ data: [4, 10, 2, 4, 6, 7, 20] }]}
                  height={200}
                  margin={{ top: 0, left: 0, right: 0, bottom: 20 }}
                  leftAxis={null}
                  slotProps={{
                    bar: {
                      clipPath: `inset(0px round 10px 10px 10px 10px)`,
                    },

                  }}
                  bottomAxis={{
                    disableLine: true,
                    disableTicks: true,
                  }}
                />

              </CardContent>
              <div style={{ height: "16px" }}></div>
            </Card>

            <div style={{ height: 30 }}></div>
            <Typography style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
              Loja:
              <div style={{ width: 10 }}></div>
              <FormControl style={{ width: 150 }}>
                <Select
                  value={statusLoja}
                  placeholder="Status"
                  onChange={handleStatus}
                  color="secondary"
                  style={{ color: statusLoja === "aberto" ? "green" : "tomato" }}
                >
                  <MenuItem value={"aberto"} style={{ color: "green" }}>Aberto</MenuItem>
                  <MenuItem value={"fechado"} style={{ color: "tomato" }}>Fechado</MenuItem>
                </Select>
              </FormControl>
              <div style={{ width: 10 }}></div>
              <IconButton size="large" color="inherit" style={{ color: "#464545" }} onClick={handleConfigure}>
                <SettingsOutlined />
              </IconButton>
            </Typography>

            <div style={{ height: 20 }}></div>


            <Alert
              variant="outlined"
              severity="info"
              onClose={() => { }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                color: "#464545", fontSize: 14
              }}
            >
              <div>
                <b style={{ fontSize: 16 }}>Status da loja</b><br />
                Altere ou configure o status da sua loja a qualquer momento
              </div>
            </Alert>
          </Grid>
        </Grid>
      </div>

    </ThemeProvider>
  );
}

export default Home;