import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../../functions/utils';

function QRCode({ onChange, colorPrimary, colorSecondary, colorTertiary }, props) {

  useEffect(() => {

  }, []);

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>


    </ThemeProvider>
  );
}

export default QRCode;