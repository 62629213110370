import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../../functions/utils';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function Suporte({ onChange, colorPrimary, colorSecondary, colorTertiary }, props) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  useEffect(() => {

  }, []);

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
<div style={{ top: 90, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute" }}>
        SUPORTE
      </div>

    </ThemeProvider>
  );
}

export default Suporte;