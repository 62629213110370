import { Container, AppBar, Toolbar, Button, IconButton, Typography, Card, CardContent, SwipeableDrawer, TextField } from '@mui/material';
import { ArrowBackOutlined, CardMembershipOutlined, CheckCircle, CreditCardOutlined, PaymentsOutlined, PixOutlined } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { toHour, createNewTheme } from '../functions/utils';
import * as React from 'react';

import Cookies from 'js-cookie';
import axios from 'axios';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import PropTypes from 'prop-types';
import Rating from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import Snackbar from '@mui/material/Snackbar';
import LoadingComponent from '../components/LoadingComponent';

import '@fontsource/roboto/400.css';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" style={{ fontSize: "36px", padding: "4px" }} />,
    label: 'Muito insatisfeito',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" style={{ fontSize: "36px", padding: "4px" }} />,
    label: 'Insatisfeito',
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" style={{ fontSize: "36px", padding: "4px" }} />,
    label: 'Neutro',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" style={{ fontSize: "36px", padding: "4px" }} />,
    label: 'Satisfeito',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" style={{ fontSize: "36px", padding: "4px" }} />,
    label: 'Muito satisfeito',
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid #dcdbdb`,
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: "#f2f2f2",
  padding: theme.spacing(2),
}));

function Pedido(props) {

  const state = useLocation().state;
  const [loading, setLoading] = useState(false);
  const { window } = props;
  const page = state ? state.page : 'home';
  const [colorPrimary, setColorPrimary] = useState(state ? state.colorPrimary : "217, 217, 217");
  const [colorSecondary, setColorSecondary] = useState(state ? state.colorSecondary : "217, 217, 217");
  const [colorTertiary, setColorTertiary] = useState(state ? state.colorTertiary : "217, 217, 217");
  const [pedidos, setPedidos] = useState([]);
  const [nota, setNota] = useState(3);
  const [comentario, setComentario] = useState("");
  const [open, setOpen] = useState(false);
  const [isMesa, setIsMesa] = useState(false);
  const [numeroMesa, setNumeroMesa] = useState(false);
  const [expanded, setExpanded] = useState('panel1');
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const container = window !== undefined ? () => window().document.body : undefined;
  const navigate = useNavigate();

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleBack = () => {
    Cookies.remove('idp');
    Cookies.remove('idpT');
    const numeroMesa = Cookies.get('nM');
    if (numeroMesa) {
      navigate(`/?nm=${btoa(numeroMesa)}`, { replace: true, state: { page: page } })
    } else {
      navigate("/", { replace: true, state: { page: page } })
    }
  };

  const handleAvaliacao = (nota) => {
    setNota(nota)
  };

  const handleComentarioChange = (event) => {
    setComentario(event.target.value)
  };

  const handleAvaliar = () => {
    handleOpenLoading();
    const avaliacao = `${nota} -- ${comentario}`

    const urlBase = Cookies.get('UBs');
    axios.post(`${urlBase}/avaliar.php`, {
      idpedidos: pedidos[0].idpedidos,
      nota: avaliacao
    })
      .then(response => {
        response = response.data
        if (!response) {
          handleSnack("Não foi possivel avaliar seu pedido, verifique sua conexão e tente novamente.")
          return;
        }
        if (response.resposta === "falha") {
          handleSnack("Não foi possivel avaliar seu pedido, verifique sua conexão e tente novamente.")
          return;
        }
        if (response.resposta === "sucesso") {
          setOpen(false)
          handleSnack("Seu pedido foi avaliado com sucesso.")
          return;
        }
        handleSnack("Não foi possivel avaliar seu pedido, verifique sua conexão e tente novamente.")
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));

  };

  useEffect(() => {
    handleOpenLoading();

    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;
    
    if (colorPrimary) {
      setColorPrimary(Cookies.get("clPmr"))
      setColorSecondary(Cookies.get("clScr"))
      setColorTertiary(Cookies.get("clTcr"))
    }

    const numeroMesa = Cookies.get('nM');
    if (numeroMesa) {
      setIsMesa(true);
      setNumeroMesa(numeroMesa)
    }
    const idp = Cookies.get('idp');
    if (idp) {
      getPedido(idp);
      return
    }
    const idpT = Cookies.get('idpT');
    if (idpT) {
      getPedidoAvulso(idpT);
      return
    }
    handleBack()
  }, []);

  const getPedidoAvulso = (idp) => {
    const urlBase = Cookies.get('UBs');
    axios.get(`${urlBase}/pedido.php?timestamp=${idp}`)
      .then(response => {
        if (response.data) {
          console.log(JSON.parse(atob(pedidos[0].detalhes)))
          setPedidos(response.data)
          if (response.data[0].avaliacao) {
            const arrAvaliacao = response.data[0].avaliacao.split(" -- ")
            setNota(parseInt(arrAvaliacao[0]))
            setComentario(arrAvaliacao[1])
          }
          handleCloseLoading();
          return
        }
        console.log("AQUI")
        handleSnack("Número do pedido não foi encontrado. Por favor verifique e tente novamente.");
        handleOpenLoading();
        setTimeout(handleBack, 3000);
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const getPedido = (idp) => {
    const urlBase = Cookies.get('UBs');
    axios.get(`${urlBase}/pedido.php?idpedidos=${idp}`)
      .then(response => {
        if (response.data) {
          setPedidos(response.data)
          handleCloseLoading();
          return
        }
        handleSnack("Número do pedido não foi encontrado. Por favor verifique e tente novamente.");
        setTimeout(handleBack, 3000);
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const getIconForPaymentMethod = (paymentMethod) => {
    if (!paymentMethod) {
      return <PaymentsOutlined style={{ color: '#464545' }} />;
    }
    if (paymentMethod.includes("Dinheiro")) {
      return <PaymentsOutlined style={{ color: '#464545' }} />;
    }
    switch (paymentMethod) {
      case 'PIX':
        return <PixOutlined style={{ color: '#464545' }} />;
      case 'MASTERCARD':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'VISA':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'ELO':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'AMERICAN EXPRESS':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'BANRI COMPRAS':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'VERDCARD':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'HIPERCARD':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'DINNERS CLUB':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'AURA':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'CREDICARD':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'SOROCRED':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'PAN':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'ALELO':
        return <CardMembershipOutlined style={{ color: '#464545' }} />;
      case 'SODEXO':
        return <CardMembershipOutlined style={{ color: '#464545' }} />;
      case 'TICKET':
        return <CardMembershipOutlined style={{ color: '#464545' }} />;
      case 'VR BENEFICIOS':
        return <CardMembershipOutlined style={{ color: '#464545' }} />;
      case 'FLASH':
        return <CardMembershipOutlined style={{ color: '#464545' }} />;
      case 'GREENCARD':
        return <CardMembershipOutlined style={{ color: '#464545' }} />;
      case 'VB':
        return <CardMembershipOutlined style={{ color: '#464545' }} />;
      case 'IFOOD ALIMENTAÇÃO':
        return <CardMembershipOutlined style={{ color: '#464545' }} />;
      case 'LINK DE PAGAMENTO':
        return <CardMembershipOutlined style={{ color: '#464545' }} />;
      case 'PONTOS':
        return <CardMembershipOutlined style={{ color: '#464545' }} />;
      default:
        return <PaymentsOutlined style={{ color: '#464545' }} />;
    }
  };

  const getPedidoStatus = (pedido) => {
    let statusPedido = "", horaPedido = ""
    if (pedido.status === "F") {
      pedido.endereco === "Retirada" ? statusPedido = "Retirado" : statusPedido = "Entregue"
    } else {
      statusPedido = "Pedido realizado"
    }

    if (!pedido.demaishoras) {
      return <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ borderRadius: "8px" }}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography style={{ fontSize: "16px", fontWeight: "bold", color: "#1B1B1B" }}><CheckCircle style={{ color: "#33540E", fontSize: "16px", marginBottom: "-2px", marginLeft: "-8px" }} /> &nbsp;&nbsp; {statusPedido}</Typography>
          <Typography style={{ fontSize: "10px", marginLeft: "auto", color: "#999999", paddingTop: "5px", paddingRight: "10px" }}>{toHour(pedido.data)}</Typography>
        </AccordionSummary>
      </Accordion>;
    }

    const arrHoras = pedido.demaishoras.split(",");
    if (arrHoras[2]) {
      statusPedido = statusPedido
      horaPedido = toHour(arrHoras[2])
    }

    if (arrHoras[1] && !arrHoras[2]) {
      statusPedido = pedido.endereco === "Retirada" ? "Aguardando retirada" : "Saiu para entrega"
      horaPedido = toHour(arrHoras[1])
    }

    if (arrHoras[0] && !arrHoras[1] && !arrHoras[2]) {
      statusPedido = "Preparando"
      horaPedido = toHour(arrHoras[0])
    }

    return <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ borderRadius: "8px" }}>

      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography style={{ fontSize: "16px", fontWeight: "bold", color: "#1B1B1B" }}><CheckCircle style={{ color: "#33540E", fontSize: "16px", marginBottom: "-2px", marginLeft: "-8px" }} /> &nbsp;&nbsp; {statusPedido}</Typography>
        <Typography style={{ fontSize: "10px", marginLeft: "auto", color: "#999999", paddingTop: "5px", paddingRight: "10px" }}>{horaPedido}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px" }}>

        {arrHoras[1] && arrHoras[2] && (
          <>
            <Typography style={{ display: "flex", alignContent: "center" }}>
              <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
                <CheckCircle style={{ color: "#33540E", fontSize: "16px", marginBottom: "-2px" }} /> &nbsp;&nbsp; {pedido.endereco === "Retirada" ? "Aguardando retirada" : "Saiu para entrega"}
              </Typography>
              <Typography style={{ fontSize: "10px", marginLeft: "auto", color: "#999999", paddingTop: "4px" }}>
                {toHour(arrHoras[1])}
              </Typography>
            </Typography>

            <div style={{ height: "35px" }}></div>
          </>
        )}

        {arrHoras[0] && arrHoras[1] && (
          <>
            <Typography style={{ display: "flex", alignContent: "center" }}>
              <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
                <CheckCircle style={{ color: "#33540E", fontSize: "16px", marginBottom: "-2px" }} /> &nbsp;&nbsp; Preparando
              </Typography>
              <Typography style={{ fontSize: "10px", marginLeft: "auto", color: "#999999", paddingTop: "4px" }}>
                {toHour(arrHoras[0])}
              </Typography>
            </Typography>

            <div style={{ height: "35px" }}></div>
          </>
        )}

        <Typography style={{ display: "flex", alignContent: "center" }}>
          <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
            <CheckCircle style={{ color: "#33540E", fontSize: "16px", marginBottom: "-2px" }} /> &nbsp;&nbsp; Pedido realizado
          </Typography>
          <Typography style={{ fontSize: "10px", marginLeft: "auto", color: "#999999", paddingTop: "4px" }}>
            {toHour(pedido.data)}
          </Typography>
        </Typography>
      </AccordionDetails>
    </Accordion>
  };

  const getTab = (pedido) => {
    if (!pedido) {
      return ""
    }
    if (pedido.status === "F") {
      return `Pedido concluído: ${pedido.data}`
    } else {
      return `Pedido: ${pedido.data}`
    }
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>
        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            {getTab(pedidos[0])}
          </Toolbar>
        </AppBar>

        <LoadingComponent open={loading} />

        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleClose}
          message={msgSnack}
        />

        <div style={{ height: "83px" }}></div>

        <SwipeableDrawer
          container={container}

          anchor="bottom"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          disableSwipeToOpen={true}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <div style={{ backgroundColor: "#D6D6D6", height: "4px", width: "32px", borderRadius: "100px", margin: "15px auto" }}></div>

          <Typography style={{ fontSize: "14px", textAlign: "center", color: "#464444", fontWeight: "bold" }}>
            Avalie seu pedido abaixo.
          </Typography>

          <div style={{ height: "12px" }}></div>

          <Typography style={{ textAlign: "center" }}>
            <StyledRating
              name="highlight-selected-only"
              value={nota}
              IconContainerComponent={IconContainer}
              getLabelText={(value) => customIcons[value].label}
              onChange={(value) => handleAvaliacao(value.target.value)}
              highlightSelectedOnly
            />
          </Typography>

          <Typography style={{ fontSize: "14px", textAlign: "center", color: "#464444", fontWeight: "bold" }}>
            Que tal comentar em que podemos melhorar.
          </Typography>
          <div style={{ height: "16px" }}></div>
          <Typography style={{ paddingLeft: "20px", paddingRight: "22px" }}>
            <TextField
              id="outlined-multiline-static"
              label="Comentários"
              multiline
              rows={4}
              value={comentario}
              onChange={handleComentarioChange}
              style={{ width: "100%" }}
            />
          </Typography>

          <Button variant="contained" size="large" style={{ textTransform: "capitalize", margin: "20px" }} onClick={handleAvaliar}>Avaliar</Button>

        </SwipeableDrawer>


        {pedidos[0] && (
          <>
            {getPedidoStatus(pedidos[0])}

            <div style={{ height: "30px" }}></div>

            <Typography style={{ color: "#1B1B1B", fontSize: "16px", fontWeight: "bold" }}>
              {!isMesa && (
                <>
                  {pedidos[0].endereco === "Retirada" ? "Opção de entrega:" : "Entregar em:"}
                  <div style={{ height: "10px" }}></div>
                  <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                    <CardHeader
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      title={pedidos[0].endereco === "Retirada" ? "Retirada" : "Casa"}
                      titleTypographyProps={{ fontSize: "12px", fontWeight: "500", color: "#999" }}
                      subheader={pedidos[0].endereco === "Retirada" ? "-" : pedidos[0].endereco}
                      subheaderTypographyProps={{ fontSize: "14px", fontWeight: "bold", color: "#1B1B1B" }}
                    />
                  </Card>
                  <div style={{ height: "12px" }}></div>
                </>
              )}
              Detalhes do pedido
              <div style={{ height: "10px" }}></div>

              {isMesa && (
                <>
                  <b style={{ color: "#888", fontSize: "13px" }}>Número da mesa: {numeroMesa}</b>
                  <div style={{ height: "12px" }}></div>
                </>
              )}

              {!isMesa && (
                <>
                  <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                    <CardHeader
                      title="Taxa de entrega"
                      titleTypographyProps={{ fontSize: "12px", fontWeight: "500", color: "#999" }}
                      subheader={(pedidos[0].taxa !== "" && pedidos[0].taxa !== "Grátis" && pedidos[0].taxa !== "combinar") ? `R$ ${pedidos[0].taxa}` : `-`}
                      subheaderTypographyProps={{ fontSize: "12px", fontWeight: "bold", color: "#1B1B1B" }}
                    />
                  </Card>
                  <div style={{ height: "10px" }}></div>
                </>
              )}

              {!isMesa && (
                <>
                  <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                    <CardHeader
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      avatar={getIconForPaymentMethod(pedidos[0].formapagamento)}
                      title={pedidos[0].formapagamento}
                      titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
                    />
                  </Card>
                  <div style={{ height: "10px" }}></div>
                </>
              )}

              <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                <CardContent>
                  <Typography style={{ fontSize: "12px", color: "#999" }}>
                    N° {pedidos[0].data}
                  </Typography>
                  <div style={{ height: "10px" }}></div>
                  <Typography>
                    {(JSON.parse(atob(pedidos[0].detalhes))).map((detalhes) => (
                      <>
                        <Typography style={{ fontSize: "12px", color: "#464444" }}>
                          {detalhes.quantidade}x {detalhes.nome}
                        </Typography>
                      </>
                    ))}
                  </Typography>
                </CardContent>

                {pedidos[0].status === "F" && (
                  <CardActions disableSpacing>
                    <Typography style={{ textAlign: "center", width: "100%" }}>
                      <Button variant="text" style={{ fontWeight: "bold", textTransform: "capitalize" }} onClick={() => setOpen(true)}>Avaliar</Button>
                    </Typography>
                  </CardActions>
                )}

                <div style={{ height: "16px" }}></div>
              </Card>
            </Typography>

          </>
        )}
        <div style={{ height: "100px" }}></div>

      </Container>
    </ThemeProvider>
  );
}

export default Pedido;