import { AppBar, Avatar, Box, Divider, FormControl, IconButton, InputAdornment, MenuItem, Modal, Select, TextField, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { ArticleOutlined, Close, ContactSupportOutlined, ContentCopy, EditOutlined, HomeOutlined, LayersOutlined, ListAltOutlined, QrCodeOutlined, RestaurantOutlined, Search, SettingsOutlined, ShareOutlined } from '@mui/icons-material';
import { stringAvatar } from '../functions/utils';

import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "8px",
  };

const SideMenu = ({ onChange, update, colorPrimary }) => {

    const [statusLoja, setStatusLoja] = useState("aberto");
    const [openModal, setOpenModal] = useState(false);
    const [currentPage, setCurrentPage] = useState("home");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleShare = () => {
        handleOpenModal()
    }

    const handleStatus = (event) => {
        setStatusLoja(event.target.value)
    }

    const changePage = (page) => {
        onChange("", page);
        setCurrentPage(page)
    }

    useEffect(() => {

    }, [currentPage]);

    return (
        <>
            <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto", height: 75 }}>
                <Toolbar>

                    {isMobile ? <></> :
                        <>
                            <TextField
                                color='secondary'
                                placeholder="Buscar"
                                sx={{ m: 1, width: '25ch', marginLeft: "315px" }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
                                }}
                            />

                            <IconButton size="large" color="inherit" style={{ color: "#FFF", marginLeft: "auto" }} onClick={handleShare}>
                                <ShareOutlined />
                            </IconButton>
                            <Typography style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                Loja:
                                <div style={{ width: 10 }}></div>
                                <FormControl style={{ width: 150 }}>
                                    <Select
                                        value={statusLoja}
                                        placeholder="Status"
                                        onChange={handleStatus}
                                        color="secondary"
                                        style={{ color: statusLoja === "aberto" ? "green" : "tomato" }}
                                    >
                                        <MenuItem value={"aberto"} style={{ color: "green" }}>Aberto</MenuItem>
                                        <MenuItem value={"fechado"} style={{ color: "tomato" }}>Fechado</MenuItem>
                                    </Select>
                                </FormControl>
                                <div style={{ width: 10 }}></div>
                                <Avatar {...stringAvatar("Joelson Lucas")} style={{ margin: "auto", width: "38px", height: "38px", fontSize: 14 }} />

                            </Typography>
                        </>
                    }
                </Toolbar>
            </AppBar>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
            >
                <Box sx={style}>
                    <Typography style={{ fontSize: 18, color: "#464545", fontWeight: "bold", display: "flex", padding: 16 }}>
                        <ShareOutlined /> <div style={{ width: 10 }} /> Compartilhar <Close style={{ marginLeft: "auto", cursor: "pointer" }} onClick={handleCloseModal} />
                    </Typography>
                    <Divider />
                    <Typography style={{ fontSize: 16, padding: 16, fontWeight: "bold" }}>
                        Copie o link abaixo para enviar<br />
                        <Typography style={{ width: "100%", border: "solid 1px #DCDBDB", padding: 10, borderRadius: "4px", cursor: "pointer" }}>dedicado.mely.online <ContentCopy style={{ float: "right" }} /></Typography>
                    </Typography>
                </Box>
            </Modal>

            {isMobile ? <></> :
                <Drawer
                    variant="permanent"
                    anchor="left"
                >
                    <img src="https://mely.online/imagens/mely-logo-white.png" style={{ width: 100, marginLeft: 20 }} />
                    <List style={{ width: 315, padding: 20 }}>
                        <ListItem button style={{ background: currentPage === "home" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("home")}>
                            <HomeOutlined style={{ marginRight: 14, color: "#464545" }} />
                            <ListItemText primary="Home" primaryTypographyProps={{ color: currentPage === "home" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                        </ListItem>
                    </List>
                    <div style={{ height: 10 }}></div>
                    <Typography style={{ fontSize: 14, fontWeight: "bold", color: "#999", paddingLeft: 40 }}>Geral</Typography>
                    <List style={{ width: 315, padding: "5px 20px 0px 20px" }}>
                        <ListItem button style={{ background: currentPage === "pedidos" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("pedidos")}>
                            <ArticleOutlined style={{ marginRight: 14, color: "#464545" }} />
                            <ListItemText primary="Pedidos" primaryTypographyProps={{ color: currentPage === "pedidos" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                        </ListItem>
                        <ListItem button style={{ background: currentPage === "cardapio" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("cardapio")}>
                            <RestaurantOutlined style={{ marginRight: 14, color: "#464545" }} />
                            <ListItemText primary="Cardápio" primaryTypographyProps={{ color: currentPage === "cardapio" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                        </ListItem>
                        <ListItem button style={{ background: currentPage === "inventario" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("inventario")}>
                            <ListAltOutlined style={{ marginRight: 14, color: "#464545" }} />
                            <ListItemText primary="Inventário" primaryTypographyProps={{ color: currentPage === "inventario" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                        </ListItem>
                        <ListItem button style={{ background: currentPage === "qrcode" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("qrcode")}>
                            <QrCodeOutlined style={{ marginRight: 14, color: "#464545" }} />
                            <ListItemText primary="QR Code" primaryTypographyProps={{ color: currentPage === "qrcode" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                        </ListItem>
                        <ListItem button style={{ background: currentPage === "personalizar" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("personalizar")}>
                            <EditOutlined style={{ marginRight: 14, color: "#464545" }} />
                            <ListItemText primary="Personalizar" primaryTypographyProps={{ color: currentPage === "personalizar" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                        </ListItem>
                        <ListItem button style={{ background: currentPage === "pdv" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("pdv")}>
                            <LayersOutlined style={{ marginRight: 14, color: "#464545" }} />
                            <ListItemText primary="PDV" primaryTypographyProps={{ color: currentPage === "pdv" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                            <Typography style={{ background: "#F94A3D", color: "white", padding: "0px 10px 0px 10px", borderRadius: 4 }}>NOVO</Typography>
                        </ListItem>
                    </List>

                    <div style={{ height: 20 }}></div>
                    <Typography style={{ fontSize: 14, fontWeight: "bold", color: "#999", paddingLeft: 40 }}>Outras funções</Typography>
                    <List style={{ width: 315, padding: "5px 20px 0px 20px" }}>
                        <ListItem button style={{ background: currentPage === "configuracoes" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("configuracoes")}>
                            <SettingsOutlined style={{ marginRight: 14, color: "#464545" }} />
                            <ListItemText primary="Configurações" primaryTypographyProps={{ color: currentPage === "configuracoes" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                        </ListItem>
                        <ListItem button style={{ background: currentPage === "suporte" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("suporte")}>
                            <ContactSupportOutlined style={{ marginRight: 14, color: "#464545" }} />
                            <ListItemText primary="Suporte" primaryTypographyProps={{ color: currentPage === "suporte" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                        </ListItem>
                    </List>
                </Drawer>
            }
        </>
    );
};

export default SideMenu;
